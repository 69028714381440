<template>
  <div v-if="(hover || editOn) && !inline"  class="mt-n4 d-flex justify-center " >

    <ft-select
        @input="$emit('input', $event)"
        @expand="editOn = $event"
        :items="items">
      <v-btn icon ><v-icon size="32" :color="wsATTENTION">mdi-plus-circle</v-icon></v-btn>
    </ft-select>

  </div>
  <ft-select
      v-else-if="hover || editOn"
      @input="$emit('input', $event)"
      @expand="editOn = $event"
      :items="items">
    <v-btn icon ><v-icon  :color="wsACCENT">mdi-pencil</v-icon></v-btn>
  </ft-select>

</template>

<script>
export default {
  name: "wsAddItem",
  props : {
    hover : {
      type : Boolean,
      default : false
    },
    items : {
      type : Array,
      default() {return [] }
    },
    inline : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      editOn : false,
    }
  }
}
</script>

<style scoped>

</style>